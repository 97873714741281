var storage = {
  /*
   * Storage prefix
   */
  prefix: '',

  handlers: [],
  /*
   * Set storage prefix
   */
  setPrefix(prefix) {
    this.prefix = prefix;
  },

  /*
   * Return mutated key
   */
  getKey(key) {
    return this.prefix + '.' + key;
  },

  /*
   * Check if exists some data in prefix
   */
  hasPrefixData() {
    for (var key in localStorage) {
      var k = key.split('.');

      if (k.length == 2 && k[0] == this.prefix)
        return true;
    }

    return false;
  },

  /*
   * Remove all data under actual prefix
   */
  removePrefixData() {
    for (var key in localStorage) {
      var k = key.split('.');

      if (k.length == 2 && k[0] == this.prefix)
        localStorage.removeItem(key);
    }
  },
  unset(key) {
    localStorage.removeItem(this.getKey(key));
  },

  /*
   * Set storage key
   */
  set(key, data) {
    // console.log('storage:set', key, data);
    if (typeof data == 'object')
      data = JSON.stringify(data);

    localStorage.setItem(this.getKey(key), data);

    //Handlers
    for (var i = 0; i < this.handlers.length; i++)
      if (this.handlers[i].type == 'set')
        this.handlers[i].callback.call(data);
  },
  is(key) {
    var json = localStorage.getItem(this.getKey(key));

    if (!json || _.isEmpty(json) || json == null || json === "null" || json === undefined || json === 'undefined' || json === 'false') {
      return false;
    }
    return true;
  },
  /*
   * Get storage key
   */
  get(key, default_value) {
    var json = localStorage.getItem(this.getKey(key));

    if (!json)
      return default_value;

    try {
      var data = JSON.parse(json);

      if (_.isObject(data) || _.isNumber(data))
        return data;

      return default_value;
    } catch (e) {
      return json;
    }
  },

  /*
   * Push new handler
   */
  pushHandler(type, callback) {
    this.handlers.push({
      type: type,
      callback: callback,
    });
  },
};
let storageVersion = '1.0.0';
storage.setPrefix('skgranitechapp' + storageVersion);
export {storage};