import {storage} from "./storage";
import {store} from "./store";

var config = {
    /*
     * Sender id for push notifications
     */
    DO_LOGS: 'do_logs',
    default_language: "sk",
    sender_id_PROD: "170992806772", //toto je z firebase project settings//PROD
    sender_id_dev: "170992806772", //toto je z firebase project settings//TEST
    version: '1.0.0',
    // env: "local",
    // env: 'dev',
    env: 'PROD'  ,

    defaultAppConfig: {
        prod_url: "https://system.granitech.org",
        dev_url: "https://granitech.starprocess.sk",
        do_logs: true,
    },
    getSenderId: () => {
        if (config.env === "PROD") {
            return config.sender_id_PROD;
        }
        return config.sender_id_dev;
    },
    resolveEnv() {
        if (window.location.host.match(/192\.168/) || window.location.host.match(/localhost/)) {
            config.env = 'local';
        }
        if (window.location.host === 'granitech-app.starprocess.sk') {
            config.env = 'dev';
        }
        if (window.location.host === 'app.granitech.org') {
            config.env = 'PROD';
        }
    },
    pusherKey() {
        if (config.env === 'PROD') {
            return '43f19a50ad99a253a9bc';
        }
        if (config.env === 'dev') {
            return '50440955a6d85fd378b9';
        }
        return 'b517196bc7120de9d9d1';
    },
    getBackendUrl: () => {
        console.log(config.env);
        if (config.env === "PROD") {
            return config.app('prod_url');
        }
        if (config.env === "dev") {
            return config.app('dev_url');
        }
        return "http://granitech-admin.local";
    },
    app(key, defaultValue) {
        let cfg = storage.get('appConfig', {});
        if (typeof cfg[key] !== 'undefined') {
            return cfg[key];
        }
        if (typeof config.defaultAppConfig[key] !== 'undefined') {
            return config.defaultAppConfig[key];
        }

        console.log('cfg.app return default', key, defaultValue);
        return defaultValue;
    },
    loadFromServer() {
        return new Promise((resolve, reject) => {
            beRequestFail('Common', 'Loadappconfig', {
                user_id: store.state.user.id,
                language: storage.get('language'),
                currency: store.state.currency
            }).then(result => {
                storage.set('appConfig', result);
                store.commit('currency', result.currency);
                resolve();
            }).catch(err => {
                if (err === 'not_connected') {
                    $$('#not_connected').show();
                    $$('#connecting').remove();
                    return;
                }
                defaultError(err);
            });
        })
    }
};

export {config};
