<template>
    <f7-list-item
            :text="item.description"
            @click="addToCart(item, $event)"
    >
        <img v-if="item.image" class="thumbnail" slot="media"
             :src="cfg.getBackendUrl()+'/storage/'+item.image"/>
        <div slot="title" class="lower-in-cart" style="margin-bottom: 5px;">
            {{($store.getters.cart[item.id] ? $store.getters.cart[item.id].volume+'x ' :
            '')+price}} {{currency}}
            <f7-icon v-if="$store.getters.cart[item.id]" f7="minus_circle" class="lower-in-cart"></f7-icon>
        </div>
        <div slot="inner-start" class="display-flex justify-content-space-between">
            <div>
                {{item.name}}
            </div>
            <div v-if="item.discount > 0 && cfg.app('s_d', true)" class="small-font">
                {{$t('discount')}} {{item.discount}}% <span style="text-decoration: line-through">{{item['original_price_' + this.$store.state.user.currency]}}</span>
                {{currency}}
            </div>
        </div>
    </f7-list-item>
</template>
<script>
    export default {
        props: ['item'],
        computed: {
            price() {
                return parseFloat(this.item['price_' + this.$store.state.user.currency]);
            },
        },
        methods: {
            lowerCart(item) {
                this.$store.commit('lowerCart', item.id);
            },
            addToCart: function (item, $event) {
                let itemId = item.id;
                if (parseInt(item.active) !== 1 || item.deleted_at) {
                    return;
                }
                let isInCart = typeof this.$store.state.cart[itemId] !== "undefined";
                if ($event && $event.target && $event.target.className.match(/lower-in-cart/) && isInCart) {
                    return this.lowerCart(item);
                }
                console.log('addToCart event', $event);
                if (isInCart) {
                    item = this.$store.state.cart[itemId];
                    item.volume++;
                } else {
                    item.volume = 1;
                }
                this.$store.commit('addToCart', item)
            }
        }
    }
</script>