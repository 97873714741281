// Import Vue
import Vue from 'vue';

window.moment = require('moment');
// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.scss';

window._ = require('lodash');
// Import App Component
import App from '../components/app.vue';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

import {config} from './config';

import Dom7 from 'dom7';

window.$$ = Dom7;

window.cfg = config;
cfg.resolveEnv();
import {storage} from './storage';

window.storage = storage;

import {store} from './store';

Vue.prototype.$store = store;
window.store = store;

window.l = require('./log');
l.group = ('' + Math.random()).replace(/[^0-9]/, '');
l.a('App started');

import VueI18n from 'vue-i18n';

const messages = require('./messages');
Vue.use(VueI18n);

window.supportedLanguages = ['sk', 'cz', 'hu'];
if (typeof navigator.language !== 'undefined' && navigator.language !== '' && storage.get('language', false) === false) {
    let locale = navigator.language;
    if (locale.match(/-/)) {
        let parts = locale.split('-');
        locale = parts[1].toLowerCase();
    }
    if (supportedLanguages.indexOf(locale) > -1) {
        storage.set('language', locale);
    }
}


const i18n = new VueI18n({
    locale: storage.get('language', cfg.default_language), // set locale
    messages, // set locale messages
});

window.http = require('vue-resource');
Vue.mixin({
    data() {
        return {
            moment: moment,
            storage: storage,
            cfg: cfg,
        }
    },
    methods: {
        backButton() {
            backButton();
        },
        navigate: function (url) {
            vueApp.$f7.views.main.router.navigate(url);
        },
        externalLink: function (url) {
            window.open(url);
        },
        toast(text, icon, timeout, callback) {
            let params = {
                text: text,
                position: 'center',
                closeTimeout: timeout ? timeout : 2000,

            };
            if (icon) {
                params.icon = '<i class="f7-icons">' + icon + '</i>';
            }
            if (callback) {
                params.on = {
                    close: callback,
                }
            }
            vueApp.$f7.toast.create(params).open();
        },
        getDevice() {
            if (typeof window.device === 'undefined') {
                l.d('typeof window.device2');
                return {
                    platform: 'browser'
                };
            }
            return window.device;
        },
        discountCompany(price) {
            return this.discount(price, this.$store.getters.cfg.discount);
        },
        discount(price, discountAmount) {
            console.log('discount init', price, discountAmount);
            price = parseFloat(price);

            if (parseFloat(discountAmount) > 0) {
                let discount = price / 100 * parseFloat(discountAmount);
                console.log('discount', discount, discountAmount);
                return (Math.round((price - discount) * 100)/100).toFixed(2);
            }
            return (Math.round(price*100)/100).toFixed(2);
        }
    },
    computed: {
        currency() {
            return this.$store.state.cfg.currencies[this.$store.state.user.currency];
        }
    }
});


import {beGetRequestSilent, beRequest, beRequestSilent, beRequestFail} from './../js/api';

window.beRequest = beRequest;
window.beGetRequestSilent = beGetRequestSilent;
window.beRequestSilent = beRequestSilent;
window.beRequestFail = beRequestFail;

window.defaultError = function (err) {
    l.e('defaultError');
    endloading();
    console.log(err);
    vueApp.$f7.dialog.alert(vueApp.$t('general_error'), vueApp.$t('error'));
};
Vue.config.errorHandler = function (err, vm, info) {
    let handler, current = vm;
    l.e('Vue error');
    l.e("message: " + JSON.stringify(err.message));
    l.e("stack: " + JSON.stringify(err.stack));
    l.e("info: " + JSON.stringify(info));
    if (vm.$options.errorHandler) {
        handler = vm.$options.errorHandler;
    } else {
        while (current.$parent) {
            current = current.$parent;
            if (handler = current.$options.errorHandler) break;
        }
    }
    if (handler) {
        handler.call(current, err, vm, info);
    }

    console.error(err, vm, info);
};
window.onerror = function (msg, url, line, col, error) {
    // Note that col & error are new to the HTML 5 spec and may not be
    // supported in every browser.  It worked for me in Chrome.
    var extra = !col ? '' : '\ncolumn: ' + col;
    extra += !error ? '' : '\nerror: ' + error;

    // You can view the information in an alert to see things working like this:
    let text = msg + '\nurl: ' + url + '\nline: ' + line + extra;
    l.e('General JS error: ' + text);
    //ajaxRequest('JavascriptError', {msg: text});
    // TODO: Report this error via ajax so you can keep track
    //       of what pages have JS issues

    var suppressErrorAlert = true;
    // If you return true, then error alerts (like in older versions of
    // Internet Explorer) will be suppressed.
    return suppressErrorAlert;
};

window.loading = function () {
    $$('#loader').css('display', 'flex');
};
window.endloading = function () {
    $$('#loader').css('display', 'none');
};
window.loadingSmall = function () {
    $$('#loaderSmall').css('display', 'flex');
};
window.endloadingSmall = function () {
    $$('#loaderSmall').css('display', 'none');
};

window.backButton = function () {
    l.a('backbutton');
    if (($$('.panel-right').hasClass('panel-in')) || ($$('#panel-left').hasClass('panel-in'))) { // #leftpanel and #rightpanel are id of both panels.
        l.d('panel close');
        if (vueApp.$f7.panel) {
            vueApp.$f7.panel.close();
        }
        return false;
    } else if ($$('.modal-in').length > 0) {
        l.d('modal close');
        if (vueApp.$f7.popup) {
            vueApp.$f7.popup.close();
        }
        if (vueApp.$f7.popover) {
            vueApp.$f7.popover.close();
        }
        return false;
    } else if (storage.get('currentPage') === 'index') {
        l.d('exit');
        vueApp.$f7.dialog.confirm(vueApp.$t('Skutočne chcete ukončiť aplikáciu?'), function () {
                navigator.app.exitApp();
            },
            function () {
            });
    } else {
        // l.d('history back');
        $$('input, textarea').blur();
        //tento prvy timeout je nato aby sa stihla schovat klavesnica ak bola otvorena po .blur()
        setTimeout(() => {
            let currentPage = storage.get('currentPage');
            vueApp.$f7.views.main.router.back();
            // setTimeout(() => {
            //Tento timeout je hack na router.back() ked sa sofer vrati po telefonate na zoznam soferov, a route history je prazdna.
            // if (currentPage === storage.get('currentPage')) {
            //     vueApp.navigate('/');
            // }
            // }, 1000);
        }, 200);
    }
}
window.setCurrentPage = function (page) {
    l.a(page + ' mounted');
    let previousPage = storage.get('currentPage');
    storage.set('previousPage', previousPage);
    storage.set('currentPage', page);
    store.commit('currentPage', page);
    vueApp.$emit(page + '-mounted');
};
$$(document).on('page:afterin', function (e, page) {
    console.log('page:afterin', e, page);
    setCurrentPage(e.detail.route.name);
});
let storedState = storage.get('state', false);
if (storedState) {
    store.commit('loadState', storedState);
}

// Init App
loading();
beRequest('Common', 'Loadappconfig').then(result => {
    endloading();
    store.commit('cfg', result);
    window.vueApp = new Vue({
        el: '#app',
        i18n,
        render: (h) => h(App),

        // Register App Component
        components: {
            app: App
        },
    });
})
