<template>
    <f7-page name="order">
        <Navbar :title="$t('cart')"></Navbar>
        <Toolbar></Toolbar>
        <div v-if="Object.keys($store.getters.cart).length === 0" class="margin">
            {{$t('cart_is_empty')}}
        </div>
        <div v-else>
            <f7-list media-list>
                <ul>
                    <Item v-for="item in $store.getters.cart" :item="item" :key="'cart-item'+item.id"></Item>
                </ul>
            </f7-list>
            <div class="margin display-flex" style="justify-content: space-between">
                <div>{{$t('total')}}</div>
                <div><b>{{this.$store.getters.cartSum}} </b>{{currency}}</div>
            </div>
            <div v-if="parseFloat($store.state.user.discount) > 0" class="margin display-flex"
                 style="justify-content: space-between">
                <div>
                    {{$t('discount')}} <b>{{$store.state.user.discount}}%</b>
                </div>
                <div>
                    <b>{{discountCompany(this.$store.getters.cartSum)}}</b> {{currency}}
                </div>
            </div>
            <div class="padding">
                <textarea style="width: 100%; height: 100px;border: 1px solid #EEEEEE"
                          class="padding-half"
                          :placeholder="$t('comment...')"
                          v-model="comment"
                ></textarea>
            </div>
            <f7-button color="orange" big fill class="margin" @click="submitOrder">{{$t("order")}}</f7-button>
            <div class="bottom-fill"></div>
        </div>
    </f7-page>
</template>

<script>
    import Toolbar from '../components/toolbar';
    import Item from '../components/item';
    import Navbar from "../components/navbar";

    export default {

        components: {Navbar, Toolbar, Item},
        data() {
            return {
                comment: ''
            }
        },
        methods: {
            submitOrder() {
                loading();
                beRequest('Customer', 'Createorder', {
                    cart: this.$store.getters.cart,
                    comment: this.comment,
                    total_price: this.discountCompany(this.$store.getters.cartSum)
                }).then(result => {
                    this.toast(this.$t('order_created'), 'checkmark_alt_circle');
                    endloading();
                    this.$store.commit('clearCart');
                    this.navigate('/create-order')
                });
            }
        },
    }

</script>