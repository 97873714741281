<template>
    <f7-page name="reg-screen-page" class="login-screen-page">
        <Navbar :title="$t('profile')"></Navbar>
        <Form :update="true"></Form>
    </f7-page>
</template>
<script>

    import Form from "../components/form";
    import Navbar from "../components/navbar";
    export default {
        //el:'#register-button',
        components: {Navbar, Form},
        data() {
            return {
            };
        },
    };

</script>
