var messages = {
    "sk": {
        "menu": "Menu",
        "item_list": "Zoznam tovaru",
        "my_profile": "M\u00f4j profil",
        "my_orders": "Moje objedn\u00e1vky",
        "logout": "Odhl\u00e1si\u0165",
        "close": "Zatvori\u0165",
        "here goes popup content": "Sem ide popup obsah",
        "cart": "Ko\u0161\u00edk",
        "order": "Objedna\u0165",
        "send": "Odosla\u0165",
        "email": "Email",
        "insert_login_name": "Zadajte prihl. meno",
        "password": "Heslo",
        "your_pass": "va\u0161e heslo",
        "enter_pass": "zadajte heslo",
        "login": "Prihl\u00e1si\u0165 sa",
        "Dont_have_account?Sign_up.": "Nem\u00e1te \u00fa\u010det? Zaregistrujte sa.",
        "create_order": "Vytvori\u0165 objedn\u00e1vku",
        "date\/var_number": "D\u00e1tum \/ VS",
        "status": "Stav",
        "price": "Suma",
        "order_detail": "Detail objedn\u00e1vky",
        "new_registration": "Nov\u00e1 registr\u00e1cia",
        "company": "Firma",
        "your_company": "n\u00e1zov firmy",
        "insert_company_name": "Zadajte n\u00e1zov firmy",
        "name": "Meno",
        "your_name": "Va\u0161e meno",
        "insert_name": "Zadajte Va\u0161e meno",
        "phone": "Telef\u00f3n",
        "your_phone": "Va\u0161e telef\u00f3nne \u010d\u00edslo",
        "insert_phone": "Zadajte Va\u0161e telef\u00f3nne \u010d\u00edslo",
        "your_email": "V\u00e1\u0161 email",
        "insert_email": "Zadajte V\u00e1\u0161 email",
        "ico": "I\u010cO",
        "your_ico": "Va\u0161e I\u010cO",
        "insert_ico": "Zadajte I\u010cO",
        "dic": "DI\u010c",
        "your_dic": "Va\u0161e DI\u010c",
        "insert_dic": "Zadajte Va\u0161e DI\u010c",
        "ic_dph": "I\u010c DPH",
        "your_ic_dph": "Va\u0161e I\u010c DPH",
        "your_password": "Va\u0161e heslo",
        "insert_password": "Zadajte Va\u0161e heslo",
        "repeat_password": "Zopakujte heslo",
        "pass_different": "Hesl\u00e1 sa nezhoduj\u00fa",
        "street_and_number": "Ulica a \u010d\u00edslo",
        "your_street_and_number": "Va\u0161a ulica a \u010d\u00edslo",
        "insert_your_street_and_number": "Zadajte Va\u0161u ulicu a \u010d\u00edslo",
        "city": "m\u011bsto",
        "your_city": "Va\u0161e mesto",
        "insert_your_city": "Zadajte Va\u0161e mesto",
        "psc": "PS\u010c",
        "your_psc": "Va\u0161e PS\u010c",
        "insert_your_psc": "Zadajte Va\u0161e PS\u010c",
        "country": "Krajina",
        "slovakia": "Slovensko",
        "czech": "\u010cesko",
        "hungary": "Ma\u010farsko",
        "shipping_adress_is_different": "Dodacia adresa je in\u00e1 ako faktura\u010dn\u00e1",
        "register": "Registrova\u0165",
        "email_exist": "Email u\u017e existuje. Pros\u00edm prihl\u00e1ste sa.",
        "cart_is_empty": "N\u00e1kupn\u00fd ko\u0161\u00edk je pr\u00e1zdny",
        "total": "Spolu",
        "discount": "Z\u013eava",
        "status_new": "Nov\u00e1",
        "status_paid": "Zaplaten\u00e1",
        "status_canceled": "Zru\u0161en\u00e1",
        "status_accepted" : "Prijatá",
        "status_delivered_unpaid" : "Dodaná - Nezaplatená",
        "status_done" : "Vybavená",
        "order_created": "Objedn\u00e1vka bola vytvoren\u00e1",
        "back": "Sp\u00e4\u0165",
        "forgot_email_send": "Odoslali sme email na",
        "reset_password_info": "Zadajte v\u00e1\u0161 email, kam po\u0161leme odkaz na vytvorenie nov\u00e9ho hesla",
        "forgot_password": "zabudli ste heslo?",
        "receive_notifications": "Odobera\u0165 nofitik\u00e1cie",
        "profile_saved": "\u00dadaje boli ulo\u017een\u00e9",
        "shipping_address": "Faktura\u010dn\u00e1 adresa",
        "comment...": "pozn\u00e1mka k objedn\u00e1vke...",
        "contact_form": "Kontakt",
        "vasa_sprava": "Va\u0161a spr\u00e1va",
        "sprava_odoslana": "Va\u0161a spr\u00e1va bola odoslan\u00e1",
        "odoslat": "Odosla\u0165 spr\u00e1vu"
    },
    "cz": {
        "menu": "Menu",
        "item_list": "Seznam zbo\u017e\u00ed",
        "my_profile": "M\u016fj profil",
        "my_orders": "Moje objedn\u00e1vky",
        "logout": "Odhl\u00e1sit",
        "close": "Zav\u0159\u00edt",
        "here goes popup content": "Sem jde popup obsah",
        "cart": "Ko\u0161\u00edk",
        "order": "Objednat",
        "send": "Odeslat",
        "email": "Email",
        "insert_login_name": "Zadejte p\u0159ihl. jm\u00e9no",
        "password": "Heslo",
        "your_pass": "va\u0161e heslo",
        "enter_pass": "zadejte heslo",
        "login": "P\u0159ihl\u00e1sit se",
        "Dont_have_account?Sign_up.": "Nem\u00e1te \u00fa\u010det? Zaregistrujte se.",
        "create_order": "Vytvo\u0159it objedn\u00e1vku",
        "date\/var_number": "Datum \/ VS",
        "status": "Stav",
        "price": "\u010c\u00e1stka",
        "order_detail": "Detail objedn\u00e1vky",
        "new_registration": "Nov\u00e1 registrace",
        "company": "Firma",
        "your_company": "n\u00e1zev firmy",
        "insert_company_name": "Zadejte n\u00e1zev firmy",
        "name": "Jm\u00e9no",
        "your_name": "Va\u0161e jm\u00e9no",
        "insert_name": "Zadejte Va\u0161e jm\u00e9no",
        "phone": "Telefon",
        "your_phone": "Va\u0161e telefonn\u00ed \u010d\u00edslo",
        "insert_phone": "Zadejte Va\u0161e telefonn\u00ed \u010d\u00edslo",
        "your_email": "V\u00e1\u0161 email",
        "insert_email": "Zadejte V\u00e1\u0161 email",
        "ico": "I\u010cO",
        "your_ico": "Va\u0161e I\u010cO",
        "insert_ico": "Yadejte I\u010cO",
        "dic": "DI\u010c",
        "your_dic": "Va\u0161e DI\u010c",
        "insert_dic": "Zadejte Va\u0161e DI\u010c",
        "ic_dph": "I\u010c DPH",
        "your_ic_dph": "Va\u0161e I\u010c DPH",
        "your_password": "Va\u0161e heslo",
        "insert_password": "Zadejte heslo",
        "repeat_password": "Opakujte heslo",
        "pass_different": "Hesla se neshoduj\u00ed",
        "street_and_number": "Ulice a \u010d\u00edslo",
        "your_street_and_number": "Va\u0161e ulice a \u010d\u00edslo",
        "insert_your_street_and_number": "Zadejte Va\u0161i ulici a \u010d\u00edslo",
        "city": "M\u011bsto",
        "your_city": "Va\u0161e m\u011bsto",
        "insert_your_city": "Zadejte Va\u0161e m\u011bsto",
        "psc": "PS\u010c",
        "your_psc": "Va\u0161e PS\u010c",
        "insert_your_psc": "Zadejte Va\u0161e PS\u010c",
        "country": "Zem\u011b",
        "slovakia": "Slovensko",
        "czech": "\u010cesko",
        "hungary": "Ma\u010farsko",
        "shipping_adress_is_different": "Dodac\u00ed adresa je jin\u00e1 ne\u017e faktura\u010dn\u00ed",
        "register": "Registrovat",
        "email_exist": "Email ji\u017e existuje. Pros\u00edm p\u0159ihla\u0161te se.",
        "cart_is_empty": "N\u00e1kupn\u00ed ko\u0161\u00edk je pr\u00e1zdn\u00fd",
        "total": "Spolu",
        "discount": "Sleva",
        "status_new": "Nov\u00e1",
        "status_paid": "Zaplacena",
        "status_canceled": "Zru\u0161ena",
        "status_accepted" : "Přijatá",
        "status_delivered_unpaid" : "Dodaná - Nezaplacená",
        "status_done" : "Vybavená",
        "order_created": "Objedn\u00e1vka byla vytvo\u0159ena",
        "back": "Zp\u011bt",
        "forgot_email_send": "Odeslali jsme email na",
        "reset_password_info": "Zadejte v\u00e1\u0161 email, kam za\u0161leme odkaz na vytvo\u0159en\u00ed nov\u00e9ho hesla",
        "forgot_password": "zapomn\u011bli jste heslo?",
        "receive_notifications": "Odeb\u00edrat nofitik\u00e1cie",
        "profile_saved": "\u00dadaje byly ulo\u017eeny",
        "shipping_address": "Faktura\u010dn\u00ed adresa",
        "comment...": "pozn\u00e1mka...",
        "contact_form": "Kontakt",
        "vasa_sprava": "Va\u0161e zpr\u00e1va",
        "sprava_odoslana": "Va\u0161e zpr\u00e1va byla odesl\u00e1na.",
        "odoslat": "Odesl\u00e1n\u00ed zpr\u00e1vy"
    },
    "hu": {
        "menu": "Menu",
        "item_list": "Term\u00e9klista",
        "my_profile": "Profilom",
        "my_orders": "Megrendel\u00e9seim",
        "logout": "Kijelentkez\u00e9s",
        "close": "Becsuk",
        "here goes popup content": "Ide j\u00f6n a popup tartalom",
        "cart": "Kos\u00e1r",
        "order": "Megrendelem",
        "send": "Elk\u00fcld",
        "email": "E-mail c\u00edm",
        "insert_login_name": "Add meg a felhaszn\u00e1l\u00f3 neved",
        "password": "Jelsz\u00f3",
        "your_pass": "jelszavad",
        "enter_pass": "add meg a jelszavad",
        "login": "Bel\u00e9p\u00e9s",
        "Dont_have_account?Sign_up.": "Nincs fi\u00f3kod? Regisztr\u00e1lj most.",
        "create_order": "Megrendel\u00e9s l\u00e9trehoz\u00e1sa",
        "date\/var_number": "D\u00e1tum \/ Ms",
        "status": "\u00e1llapot",
        "price": "Sz\u00e1mla \u00f6sszege",
        "order_detail": "Megrendel\u00e9s r\u00e9szletei",
        "new_registration": "\u00daj regisztr\u00e1ci\u00f3",
        "company": "C\u00e9gn\u00e9v",
        "your_company": "c\u00e9g neve",
        "insert_company_name": "Add meg a v\u00e1llalkoz\u00e1s nev\u00e9t",
        "name": "N\u00e9v",
        "your_name": "Neved",
        "insert_name": "Add meg a neved",
        "phone": "Telefon",
        "your_phone": "Telefonsz\u00e1mod",
        "insert_phone": "Add meg a telefonsz\u00e1mod",
        "your_email": "E-mail c\u00edmed",
        "insert_email": "Add meg az e-mail c\u00edmed",
        "ico": "Nyilv\u00e1ntart\u00e1si sz\u00e1m",
        "your_ico": "A c\u00e9ged nyilv\u00e1ntart\u00e1si sz\u00e1ma",
        "insert_ico": "Add meg a c\u00e9ged nyilv\u00e1ntart\u00e1si sz\u00e1m\u00e1t",
        "dic": "Ad\u00f3sz\u00e1m",
        "your_dic": "Ad\u00f3sz\u00e1mod",
        "insert_dic": "Add meg az ad\u00f3sz\u00e1mod",
        "ic_dph": "Uni\u00f3s ad\u00f3sz\u00e1m",
        "your_ic_dph": "Uni\u00f3s ad\u00f3sz\u00e1mod",
        "your_password": "Jelszavad",
        "insert_password": "Add meg a jeszalvad",
        "repeat_password": "Ism\u00e9teld meg a jelszavad",
        "pass_different": "A k\u00e9t jelsz\u00f3 nem azonos",
        "street_and_number": "Utca \u00e9s h\u00e1zsz\u00e1m",
        "your_street_and_number": "Utcaneved \u00e9s a h\u00e1zsz\u00e1mod",
        "insert_your_street_and_number": "Add meg az utcaneved \u00e9s a h\u00e1zsz\u00e1mod",
        "city": "Lakhely",
        "your_city": "Lakhelyed",
        "insert_your_city": "Add meg a lakhelyed",
        "psc": "Ir\u00e1ny\u00edt\u00f3sz\u00e1m",
        "your_psc": "Ir\u00e1ny\u00edt\u00f3sz\u00e1mod",
        "insert_your_psc": "Add meg az ir\u00e1ny\u00edt\u00f3sz\u00e1mod",
        "country": "Orsz\u00e1g",
        "slovakia": "Szlov\u00e1kia",
        "czech": "Csehorsz\u00e1g",
        "hungary": "Magyarorsz\u00e1g",
        "shipping_adress_is_different": "A sz\u00e1ll\u00edt\u00e1si c\u00edm k\u00fcl\u00f6nb\u00f6zik a sz\u00e1ml\u00e1z\u00e1si c\u00edmt\u0151l",
        "register": "Regisztr\u00e1ci\u00f3",
        "email_exist": "Az e-mail c\u00edm m\u00e1r l\u00e9tezik. Jelentkezz be.",
        "cart_is_empty": "A kosarad \u00fcres.",
        "total": "V\u00e9g\u00f6sszeg",
        "discount": "Kedvezm\u00e9ny",
        "status_new": "\u00daj",
        "status_paid": "Fizetve",
        "status_canceled": "T\u00f6r\u00f6lve",
        "status_accepted" : "Beérkezett",
        "status_delivered_unpaid" : "Kiszállítva - Nincs fizetve",
        "status_done" : "Rendezve",
        "order_created": "Megrendel\u00e9s l\u00e9trehozva",
        "back": "Vissza",
        "forgot_email_send": "Az e-mail c\u00edmet elk\u00fcldt\u00fck a ",
        "reset_password_info": "\u00cdrd be az e-mail c\u00edmed, ahova elk\u00fcld\u00fcnk egy linket egy \u00faj jelsz\u00f3 l\u00e9trehoz\u00e1s\u00e1hoz",
        "forgot_password": "Elfelejtetted a jelszavadat?",
        "receive_notifications": "Feliratkoz\u00e1s a r\u00f6vid \u00fczenetekre",
        "profile_saved": "Adatok mentve",
        "shipping_address": "Sz\u00e1ml\u00e1z\u00e1si c\u00edm",
        "comment...": "megjegyz\u00e9s...",
        "contact_form": "K\u00e9rd\u00e9sem van",
        "vasa_sprava": "Az \u00d6n \u00fczenete",
        "sprava_odoslana": "Az \u00fczenet elk\u00fcldve",
        "odoslat": "\u00dczenet k\u00fcld\u00e9se"
    }
};

module.exports = messages;