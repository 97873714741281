<template>
    <f7-page name="my-orders">
        <navbar :title="$t('order_detail')+' '+$store.state.orderId"></navbar>
        <div class="data-table card padding">
            <table>
                <tr>
                    <td class="label-cell">{{moment(order.created_at).format('DD.MM.YYYY HH:mm')}}<br>{{order.reference_number}}
                    </td>
                    <td class="label-cell">{{$t('status_'+order.status)}}</td>
                    <td class="label-cell">{{order.total_price}}</td>
                </tr>
            </table>
        </div>
        <f7-list>
            <ul>
                <Item v-for="item in order.items" :item="item" :key="'history-item'+item.id+order.id"></Item>
            </ul>
        </f7-list>
    </f7-page>
</template>

<script>
    import Item from "../components/item";
    import Navbar from "../components/navbar";

    export default {
        name: "order-detail",
        components: {Navbar, Item},
        data() {
            return {
                order: {
                    items: []
                }
            };
        },
        mounted() {
            loading();
            beRequest('Order', 'Detail', {
                order_id: this.$store.state.orderId
            }).then(result => {
                this.order = result.order;
                endloading();
            });
        },
    }
</script>

<style scoped>

</style>