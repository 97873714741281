import Vue from 'vue';
import Vuex from 'vuex';
import {storage} from './storage';

Vue.use(Vuex);

let store = new Vuex.Store({
    state: {
        user: {
            id: '',
            push_notifications: true
        },
        currentPage: '',
        cart: {},
        cfg: {},
        orderId: {},
    },
    getters: {
        user: state => {
            return state.user;
        },
        cfg: state => {
            return state.cfg;
        },
        cart: state => {
            return state.cart;
        },
        cartSum: state => {
            var sum = 0;
            console.log(state.cart);
            Object.keys(state.cart).forEach(key => sum += ((state.cart[key].volume) * (state.cart[key]['price_' + state.user.currency])));
            return (Math.round(sum*100)/100).toFixed(2);
        }
    },
    mutations: {
        loadState(state, data) {
            console.log("loadState mutation", data);
            for (let key in data) {
                state[key] = data[key];
            }
            console.log("state loaded", state);
            storage.set("state", state);
        },
        setUser(state, user) {
            l.d('setUser mutation ' + JSON.stringify(user));
            console.log("setUser mutation", user);
            state.user = user;
            storage.set("state", state);
        },
        currentPage(state, currentPage) {
            l.d('currentPage mutation ' + JSON.stringify(currentPage));
            console.log("currentPage mutation", currentPage);
            state.currentPage = currentPage;
            storage.set("state", state);
        },
        addToCart(state, item) {
            console.log("addToCart mutation", item);
            state.cart[item.id] = item;
            state.cart = JSON.parse(JSON.stringify(state.cart));
            storage.set("state", state);
        },
        orderId(state, itemId) {
            console.log("orderId mutation", itemId);
            state.orderId = itemId;
            storage.set("state", state);
        },
        lowerCart(state, itemId) {
            console.log("lowerCart mutation", itemId);
            let item = state.cart[itemId];
            if (!item) {
                return;
            }
            item.volume--;
            if (item.volume === 0) {
                delete state.cart[item.id];
            } else {
                state.cart[item.id] = item;
            }
            state.cart = JSON.parse(JSON.stringify(state.cart));
            storage.set("state", state);
        },
        clearCart(state) {
            state.cart = {};
            storage.set("state", state);
        },
        cfg(state, cfg) {
            state.cfg = cfg;
            storage.set("state", state);
        }
        // setCartSum(state, cartSum) {
        //     state.cart_sum = cartSum;
        // },
    }
});
export {store};