<template>
    <f7-page name="reg-screen-page" class="login-screen-page">
        <f7-navbar :title="$t('new_registration')" back-link="Back">
        </f7-navbar>
        <Form :update="false"></Form>
    </f7-page>
</template>
<script>

    import Form from "../components/form";
    export default {
        //el:'#register-button',
        components: {Form},
        data() {
            return {
            };
        },
    };

</script>
