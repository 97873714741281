<template>
    <f7-navbar :title="title" :back-link="hideBack === true ? null : $t('back')">
        <f7-nav-right>
            <f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="right"></f7-link>
        </f7-nav-right>
    </f7-navbar>
</template>
<script>
    export default {
        name: "navbar",
        props: ['title', 'hideBack']
    }
</script>


