module.exports = {
    group: '',
    d: function (text) {
        this.save(this.text(text, 'D'));
    },
    e: function (text) {
        text = this.text(text, 'E');
        this.forceSave(text);
        console.error(text);
    },
    a: function (text) {
        this.save(this.text(text, 'A'));
    },
    text: function (text, level) {
        //type, date, customer, group, text
        let cons = true;
        if (_.isObject(text)) {
            console.log(text);
            text = JSON.stringify(text);
            cons = false;
        }
        text = moment().format('YYYY-MM-DD HH:mm:ss') + '.' + moment().millisecond() + '|' +
            store.state.id + '|' + this.group + '|' + level + '|' + text;
        if (cons) {
            console.log(text);
        }
        return text;
    },
    forceSave: function (text) {
        try {
            let logs = storage.get('logs', []);
            logs.push(text);
            storage.set('logs', logs);
        } catch (e) {
            console.error(e);
        }
    },
    save: function (text) {
        if (!cfg.app(cfg.DO_LOGS, false)) {
            return;
        }
        this.forceSave(text)
    },
};
