<template>
    <f7-page name="my-orders">
        <navbar :title="$t('Kontakt')"></navbar>
        <div class="padding">
                <textarea style="width: 100%; height: 150px;border: 1px solid #EEEEEE"
                          class="padding-half"
                          :placeholder="$t('vasa_sprava')"
                          v-model="message"
                ></textarea>
        </div>
        <f7-button color="orange" big fill class="margin" @click="submit">{{$t("odoslat")}}</f7-button>
        <div class="bottom-fill"></div>
    </f7-page>
</template>

<script>
    import Navbar from "../components/navbar";

    export default {
        name: "order-detail",
        components: {Navbar},
        data() {
            return {
                message: ''
            };
        },
        methods: {
            submit() {
                loading();
                beRequest('Common', 'Contact', {
                    message: this.message
                }).then(result => {
                    this.toast(this.$t('sprava_odoslana'), 'checkmark_alt_circle');
                    this.message = '';
                    endloading();
                });
            },
        }
    }
</script>

<style scoped>

</style>