var retryCount = {};

function beRequestSilent(module, method, data) {
    let promise = doRequest(module, method, data, true);
    promise.catch(err => {
        l.d('Silent request failed ' + err);
    });
    return promise;
}

function beGetRequestSilent(url) {
    let promise = new Promise(function (resolve, reject) {
        httpRequest(cfg.getBackendUrl() + url, {}, resolve, reject, true, 'get');
    });
    promise.catch(err => {
        l.d('Silent get request failed ' + err);
    });
    return promise;
}

function beRequest(module, method, data) {
    let promise = doRequest(module, method, data, false);
    promise.catch(err => {
        if (err === 'request_in_progress') {
            return;
        }
        if (err === 'not_connected') {
            return;
        }
        defaultError(err);
    });
    return promise;
}

function beRequestFail(module, method, data) {
    return doRequest(module, method, data, false);
}

window.retryLimits = {
    callcustomer: 0,
    Findclosest: 0,
    Loadappconfig: 0,

};
window.dontLog = ['Loadphotos64', 'Cropimage'];

function doRequest(module, method, data, silent) {
    let requestId = ((new Date()).getTime()) + Math.round(Math.random() * 100);
    l.d('beRequest (' + requestId + ')' + module + '/' + method);
    if (typeof retryCount[method] !== 'undefined' && retryCount[method] > 0) {
        l.d('return in progress');
        return new Promise(function (resolve, reject) {
            reject('request_in_progress');
        });
    }
    let url = cfg.getBackendUrl() + '/api/customerapp/' + module;
    retryCount[method] = 0;
    // let key = sha1(module + method + cfg.version + store.getters.driver.id);
    var req = {
        method: method,
        request_id: requestId,
        version: cfg.version,
        params: _.merge({
            user_id: store.state.user.id
        }, data || {}),
    };

    if (dontLog.indexOf(method) < 0) {
        l.d(req);
    }
    req.params.logs = storage.get('logs', []);
    let promise = new Promise(function (resolve, reject) {
        httpRequest(url, req, resolve, reject, silent, 'post');
    });
    return promise;
}

function httpRequest(url, params, resolve, reject, silent, httpMethod) {
    console.log('httpRequest', url, params);
    let promise;
    if (httpMethod === 'get') {
        promise = http.Http.get(url, {
            timeout: 15000
        });
    } else {
        promise = http.Http.post(url, params, {
            timeout: 15000
        });
    }

    promise.then(req => {
        retryCount[params.method] = 0;
        beSuccess(req, resolve, reject, params.method);
    }, error => {
        retryCount[params.method]++;
        console.log(retryCount);
        if (error.status == 0) {
            let retryLimit = 5;
            if (typeof retryLimits[params.method] !== 'undefined') {
                retryLimit = retryLimits[params.method];
            }
            l.d('HTTP retry ' + retryCount[params.method] + '/' + retryLimit);
            if (retryCount[params.method] < retryLimit) {
                if (!silent && typeof vueApp !== 'undefined') {
                    vueApp.$f7.notification.create({
                        icon: '<i class="f7-icons">alert</i>',
                        title: vueApp.$t('connection_failed'),
                        titleRightText: vueApp.$t('incoming_call_now'),
                        subtitle: vueApp.$t('connection_trying_again') + ' ' + (retryCount[params.method]) + '/' + retryLimit,
                        text: vueApp.$t('check_connection'),
                        closeOnClick: true,
                        closeTimeout: 2500,
                    }).open();
                }
                setTimeout(function () {
                    httpRequest(url, params, resolve, reject);
                }, 1000);
                return;
            } else {
                retryCount[params.method] = 0;
                reject('not_connected');
                return;
            }
        }
        l.e('HTTP error ' + params.request_id + ' ' + retryCount[params.method]);
        l.e(error);
        if (error.status == 401) {
            storage.set("state", null);
            gps.stop();
            alert(vueApp.$t('api_unathorized'));
            window.location.reload();
            return;
        }
        retryCount[params.method] = 0;
        reject(error);
    });
};

function beSuccess(req, resolve, reject, method) {
    {
        endloading();
        if (req.body.error) {
            storage.set('logs', null);
            l.d('API response');
            l.d(req);
            if (req.body.error.code == 500) {
                vueApp.$f7.toast.create({
                    text: vueApp.$t('invalid_credentials'),
                    position: 'center',
                    closeTimeout: 2000,
                    icon: '<i class="f7-icons">info_round</i>',
                }).open();
                return;
            }
            if (parseInt(req.body.error.code) === 100) {
                vueApp.$f7.toast.create({
                    text: req.body.error.message,
                    position: 'center',
                    closeTimeout: req.body.error.close_timeout,
                    icon: '<i class="f7-icons">' + req.body.error.icon + '</i>',
                }).open();
                return;
            }
            // defaultError();
            reject(req.body.error);
            return;
        }
        if (req.body.hasOwnProperty('result')) {
            storage.set('logs', null);
            l.d('API response');
            if (dontLog.indexOf(method) < 0) {
                l.d(req);
            }
            resolve(req.body.result);
            return;
        }
        l.d('API response');
        l.d(req);
        l.e('Invalid json from BE: ' + req.body);
        reject(req.body.error);
        // defaultError(req);
    }
};
export {beRequest, beRequestSilent, beGetRequestSilent, beRequestFail}

