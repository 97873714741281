<template>
    <f7-page no-toolbar no-swipeback login-screen name="login-screen-page">
        <f7-link src="static/images/logo.png" id="sensor-logo"></f7-link>
        <!-- <f7-login-screen-title class="header-big">Granitech</f7-login-screen-title> -->

        <div>
            <!-- class="pagePadding">-->
            <!--<img class="logo" src="static/images/logo.png" alt="logo"/>-->
            <h2 class="text-align-center">Vaše logo a názov firmy</h2>
        </div>

        <f7-list form id="sensor-form">
            <f7-list-input
                    :label="$t('email')"
                    type="email"
                    validate
                    required
                    placeholder="@"
                    :error-message="$t('insert_login_name')"
                    :error-message-force="isWrongUser"
                    :value="username"
                    @input="username = $event.target.value"
            ></f7-list-input>
            <f7-list-input
                    :label="$t('password')"
                    type="password"
                    :placeholder="$t('your_pass')"
                    :error-message="$t('enter_pass')"
                    :error-message-force="isWrongPass"
                    :value="password"
                    @input="password = $event.target.value"
            ></f7-list-input>
            <f7-list-button class="button-login" @click="logIn">{{$t("login")}}</f7-list-button>
            <f7-list-button class="button-regin" href="/reg-screen-page/">{{$t("Dont_have_account?Sign_up.")}}
            </f7-list-button>
            <br>
            <f7-button class="forgot-button " @click="pswdPopupOpened = true" style="color: gray">
                {{$t('forgot_password')}}
            </f7-button>
        </f7-list>
        <!--<a class="forgot-button link" href="/forgot-screen-page/">Zabudli ste heslo?</a> TODO-->
        <f7-popup class="password-popup" :opened="pswdPopupOpened" @popup:closed="pswdPopupOpened = false">
            <f7-page>
                <f7-navbar title="">
                    <f7-nav-right>
                        <f7-link popup-close>{{$t('close')}}</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <f7-block >
                    {{$t('reset_password_info')}}
                </f7-block>
                <f7-list form id="sensor-form">
                    <f7-list-input
                            label="Email"
                            type="text"
                            :error-message="$t('insert_email')"
                            :error-message-force="isWrongForgotEmail"
                            :placeholder="$t('your_email')"
                            :value="forgotmail"
                            @input="forgotmail = $event.target.value"
                    ></f7-list-input>
                    <f7-list-button class="button-login" @click="resetPass">{{$t('send')}}</f7-list-button>
                </f7-list>
            </f7-page>
        </f7-popup>
    </f7-page>
</template>
<script>
    export default {
        components: {},
        data() {
            return {
                username: "",
                password: "",
                isWrongUser: false,
                isWrongPass: false,
                pswdPopupOpened: false,
                forgotmail: '',
                isWrongForgotEmail: false,
            };
        },
        mounted() {
        },
        methods: {
            logIn() {
                //spusta sa pri uspesnej registracii alebo pri stlaceni tlacidla LOGIN, do buducna login na otlacok?
                this.isWrongUser = false;
                if (this.username === "") {
                    this.isWrongUser = true;
                    return;
                }
                this.isWrongPass = false;
                if (this.password === "") {
                    this.isWrongPass = true;
                    return;
                }
                loading();
                beRequest('Customer', 'Login', {
                    username: this.username,
                    password: this.password
                }).then(result => {
                    this.$store.commit("setUser", result.user);
                    this.storage.set('language', result.user.country);
                    window.location.reload();
                    // this.navigate("/create-order");
                })
            },
            resetPass() {
                this.isWrongForgotEmail = false;
                if (this.forgotmail === '') {
                    this.isWrongForgotEmail = true;
                    return;
                }
                loading();
                beRequest('Customer', 'Resetpassword', {
                    email: this.forgotmail,
                    language: storage.get('language', 'sk')
                }).then(result => {
                    this.toast(this.$t('forgot_email_send') + ': ' + this.forgotmail, 'checkmark_alt_circle', 3000);
                    this.$f7.popup.close();
                    endloading();
                })
            },
        },
    };
</script>
