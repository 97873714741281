<template>
    <f7-page name="order">
        <Navbar :title="$t('create_order')" :hideBack="true"></Navbar>
        <Toolbar></Toolbar>

        <!-- List View -->
        <!-- <div class="block-title">List View Accordion</div> -->
        <div class="list accordion-list" style="margin: 0">
            <ul>
                <li class="accordion-item" v-for="(items, category) in list"
                    :key="'category'+category.replace(/ /, '')">
                    <a href="" class="item-link item-content">
                        <div class="item-inner display-flex justify-content-space-between">
                            <!--  <div class="item-title" v-for="(value, key) in object()">{{ key }}: {{ value }}{{getallkeys()}} </div> -->
                            <div class="item-title accordion-main-font">{{category}}</div>
                            <div v-if="items[0].category_discount > 0 && cfg.app('c_d', true)" class="small-font">{{$t('discount')}} {{items[0].category_discount}}%</div>
                        </div>
                    </a>
                    <div class="accordion-item-content large-font">

                        <f7-list media-list>
                            <Item v-for="item in items" :item="item" :key="'item'+item.id"></Item>
                        </f7-list>
                    </div>
                </li>
            </ul>
        </div>
        <div class="bottom-fill"></div>


    </f7-page>

</template>

<script>
    import Toolbar from '../components/toolbar';
    import Navbar from '../components/navbar';

    import Item from '../components/item';

    export default {

        components: {Toolbar, Navbar, Item},

        data() {
            return {
                list: {}
            };
        },
        mounted() {
            beRequest('Item', 'Getlist').then(result => {
                this.list = result.list;
            });
            setTimeout(this.registerPush, 2000);
        },
        methods: {
            registerPush() {
                if (cfg.env === 'local') {
                    l.d('local push');
                    return;
                }
                if (typeof PushNotification === 'undefined') {
                    l.d('PushNotification undefined');
                    return;
                }
                var senderId = this.cfg.getSenderId();
                l.d('registerPush start ' + senderId);
                window.pushNotify = PushNotification.init({
                    'browser': {},
                    'android': {
                        'senderID': senderId,
                    },
                    'ios': {
                        senderID: senderId,
                        gcmSandbox: this.cfg.env !== 'PROD',
                        alert: 'true',
                        badge: false,
                        sound: true,
                    },
                });

                window.pushNotify.on('registration', function (data) {
                    //Save registration id into local storage
                    l.d('push ok ' + data.registrationId);
                    let oldId = storage.get('device_id');
                    storage.set('device_id', data.registrationId);
                    if (oldId === data.registrationId) {
                        return;
                    }
                    beRequestSilent('Customer', 'Notificationid', {
                        registration_id: data.registrationId
                    });
                }.bind(this));

                window.pushNotify.on('error', function (e) {
                    l.e('error in register push: ' + e.message);
                    l.e(JSON.stringify(e));
                });

                window.pushNotify.on('notification', function (data) {
                    l.d('on.notification');
                    console.log(JSON.stringify(data));
                    vueApp.$f7.dialog.alert(data.message, data.title);
                });
            },

        },
    }


</script>

<style scoped>
</style>