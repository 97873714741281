
import LoginScreenPage from '../pages/login-screen-page.vue';
import RegistrationPage from '../pages/registration-screen-page.vue';
import ProfilePage from '../pages/my-profile';
import CreateOrderPage from '../pages/create-order';
import MyOrdersPage from '../pages/my-orders';
import CartScreen from '../pages/cart.vue';
import OrderDetailPage from '../pages/order-detail';
import Contact from '../pages/contact';


var routes = [
  {
    path: '/cart-screen/',
    component: CartScreen,
  },
  {
    path: '/contact',
    component: Contact,
  },
  {
    path: '/reg-screen-page/',
    component: RegistrationPage,
  },
  {
    path: '/',
    component: LoginScreenPage,
  },
  {
    path: '/my-profile/',
    component: ProfilePage,
  },
  {
    path: '/create-order',
    component: CreateOrderPage,
  },
  {
    path: '/my-orders/',
    component: MyOrdersPage,
  },
  {
    path: '/order-detail/',
    component: OrderDetailPage,
  },
];

export default routes;
