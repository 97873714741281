<template>
    <f7-app :params="f7params">

        <!-- Left panel with cover effect-->
        <!-- Right panel with reveal effect-->
        <f7-panel right reveal theme-dark>
            <!--<f7-view>-->
            <!-- <f7-page>-->
            <f7-navbar :title="$t('menu')"></f7-navbar>

            <div class="list links-list">
                <ul>
                    <li>
                        <a href="/create-order/" class="link panel-close">{{$t("item_list")}}</a>
                    </li>
                    <li>
                        <a href="/my-profile/" class="link panel-close">{{$t("my_profile")}}</a>
                    </li>
                    <li>
                        <a href="/my-orders/" class="link panel-close">{{$t("my_orders")}}</a>
                    </li>
                    <li>
                        <a href="/contact" class="link panel-close">{{$t("contact_form")}}</a>
                    </li>
                    <li style="margin-top: 100px;">
                        <a class="link panel-close" @click="$store.commit('setUser', {id: ''}); navigate('/')">{{$t("logout")}}</a>
                    </li>
                </ul>
            </div>

            <!-- </f7-page>-->
            <!-- </f7-view>-->
        </f7-panel>


        <!-- Your main view, should have "view-main" class -->
        <f7-view main class="safe-areas" :url="$store.state.user.id === '' ? '/' : '/create-order'"></f7-view>
        <div style="position:fixed; left: 0; top: 0; width: 100%; height: 100%; background: #777777; z-index: 99999; opacity: 0.5;display: none; align-items: center; justify-content: center"
             id="loader">
            <f7-preloader :size="42" color="green"></f7-preloader>
        </div>
    </f7-app>
</template>
<script>

    import routes from '../js/routes.js';

    export default {
        data() {
            return {
                // Framework7 Parameters
                f7params: {
                    name: 'Granitech', // App name
                    theme: 'auto', // Automatic theme detection


                    // App routes
                    routes: routes,
                },
                // Login screen data
                username: '',
                password: '',
            }
        },
        methods: {},
        mounted() {
            this.$f7ready((f7) => {

                // Call F7 APIs here
            });
        }
    }
</script>