<template>
    <f7-page name="my-orders">
        <f7-navbar  :title="$t('my_orders')" back-link="Back"></f7-navbar>
        <!-- <p>my orders</p> -->
        <div class="data-table">
            <table>
                <thead>
                <tr>
                    <th class="label-cell small-font">{{$t("date/var_number")}}</th>
                    <th class="label-cell small-font">{{$t("status")}}</th>
                    <th class="label-cell small-font">{{$t("price")}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item) in list" @click="openDetail(item)" :key="'order'+item.id">
                    <td class="label-cell">{{moment(item.created_at).format('DD.MM.YYYY HH:mm')}}<br>{{item.reference_number}}</td>
                    <td class="label-cell">{{$t('status_'+item.status)}}</td>
                    <td class="label-cell">{{item.total_price}} {{currency}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </f7-page>
</template>

<script>
    export default {
        name: "my-orders",

        data() {
            return {
                list: []
            };
        },
        mounted() {
            loading();
            beRequest('Customer', 'Myorders').then(result => {
                this.list = result.list;
                endloading();
            });
        },
        methods: {
            openDetail(item) {
                this.$store.commit('orderId', item.id);
                this.navigate('/order-detail/');
            }
        }

    }
</script>

<style scoped>

</style>