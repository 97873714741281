<template>
    <div>
        <f7-list id="registration-form">
            <f7-list-input
                    :label="$t('company')"
                    name="company"
                    type="text"
                    :placeholder="$t('your_company')"
                    required
                    validate
                    :value="user.company_name"
                    :error-message="$t('insert_company_name')"
                    :error-message-force="isWrongCompany"

                    @input="user.company_name=$event.target.value"
                    clear-button
            ></f7-list-input>
            <f7-list-input
                    :label="$t('name')"
                    name="name"
                    type="text"
                    :placeholder="$t('your_name')"
                    required
                    validate
                    :value="user.person_name"
                    :error-message="$t('insert_name')"
                    :error-message-force="isWrongName"

                    @input="user.person_name=$event.target.value"
                    clear-button
            ></f7-list-input>
            <f7-list-input
                    :label="$t('phone')"
                    name="phone"
                    type="tel"
                    :placeholder="$t('your_phone')"
                    required
                    validate
                    :value="user.gsm"
                    :error-message="$t('insert_phone')"
                    :error-message-force="isWrongPhone"

                    @input="user.gsm=$event.target.value"
                    clear-button
            ></f7-list-input>
            <f7-list-input
                    :label="$t('email')"
                    name="email"
                    type="email"
                    :placeholder="$t('your_email')"
                    required
                    validate
                    :value="user.email"
                    :error-message="$t('insert_email')"
                    :error-message-force="isWrongEmail"

                    @input="user.email=$event.target.value"
                    clear-button
            ></f7-list-input>
            <f7-list-input
                    :label="$t('ico')"
                    name="ico"
                    type="text"
                    :placeholder="$t('your_ico')"
                    required
                    validate
                    :value="user.ico"
                    :error-message="$t('insert_ico')"
                    :error-message-force="isWrongICO"

                    @input="user.ico=$event.target.value"
                    clear-button
            ></f7-list-input>
            <f7-list-input
                    :label="$t('dic')"
                    name="dic"
                    type="text"
                    :placeholder="$t('your_dic')"
                    required
                    validate
                    :value="user.dic"
                    :error-message="$t('insert_dic')"
                    :error-message-force="isWrongDIC"

                    @input="user.dic=$event.target.value"
                    clear-button
            ></f7-list-input>
            <f7-list-input
                    :label="$t('ic_dph')"
                    name="ic-dph"
                    type="text"
                    :placeholder="$t('your_ic_dph')"
                    :value="user.ic_dph"
                    @input="user.ic_dph=$event.target.value"
                    clear-button
            ></f7-list-input>
            <f7-list-input
                    :label="$t('password')"
                    name="password"
                    id="password"
                    type="password"
                    :placeholder="$t('your_password')"
                    required
                    validate
                    :error-message="$t('insert_password')"
                    :error-message-force="isWrongPass1"
                    :value="user.pass1"
                    @input="user.pass1=$event.target.value"
                    clear-button
            ></f7-list-input>
            <f7-list-input
                    :label="$t('password')"
                    name="password-repeat"
                    id="confirm_password"
                    type="password"
                    :placeholder="$t('repeat_password')"
                    required
                    validate
                    :error-message="$t('pass_different')"
                    :error-message-force="isWrongPass2"
                    :value="user.pass2"
                    @input="user.pass2=$event.target.value"
                    clear-button
            ></f7-list-input>
            <f7-list-item>
                <p>{{$t('shipping_address')}}</p>
            </f7-list-item>
            <f7-list-input
                    :label="$t('street_and_number')"
                    name="adress"
                    type="text"
                    :placeholder="$t('your_street_and_number')"
                    required
                    validate
                    data-validate-on-blur="true"
                    :error-message="$t('insert_your_street_and_number')"
                    :error-message-force="isWrongAdress"
                    :value="user.address"
                    @input="user.address=$event.target.value"
                    clear-button
            ></f7-list-input>
            <f7-list-input
                    :label="$t('city')"
                    name="city"
                    type="text"
                    :placeholder="$t('your_city')"
                    required
                    validate
                    data-validate-on-blur="true"
                    :error-message="$t('insert_your_city')"
                    :error-message-force="isWrongCity"
                    :value="user.city"
                    @input="user.city=$event.target.value"
                    clear-button
            ></f7-list-input>
            <f7-list-input
                    :label="$t('psc')"
                    name="psc"
                    type="number"
                    :placeholder="$t('your_psc')"
                    required
                    validate
                    data-validate-on-blur="true"
                    :error-message="$t('insert_your_psc')"
                    :error-message-force="isWrongPSC"
                    :value="user.zip"
                    @input="user.zip=$event.target.value"
                    clear-button
            ></f7-list-input>
            <f7-list-input
                    v-if="!update"
                    :label="$t('country')"
                    type="select"
                    defaultValue="sk"
                    :value="user.country"
                    :placeholder="$t('choose_country')"
                    @input="user.country=$event.target.value"
            >
                <option value="sk">{{$t("slovakia")}}</option>
                <option value="cz">{{$t("czech")}}</option>
                <option value="hu">{{$t("hungary")}}</option>
            </f7-list-input>
            <f7-list-item
                    checkbox
                    :title="$t('receive_notifications')"
                    name="demo-checkbox"
                    :checked="!!user.push_notifications"
                    class="address-checkbox"
                    @change="user.push_notifications = !user.push_notifications"
            ></f7-list-item>
            <f7-list-item
                    checkbox
                    :title="$t('shipping_adress_is_different')"
                    name="demo-checkbox"
                    :checked="!!user.shipping_address"
                    class="address-checkbox"
                    @change="shippingAddress = !shippingAddress"
            ></f7-list-item>
        </f7-list>
        <f7-list v-if="shippingAddress">
            <f7-list-input
                    :label="$t('street_and_number')"
                    name="adress2"
                    type="text"
                    :placeholder="$t('your_street_and_number')"
                    required
                    validate
                    data-validate-on-blur="true"
                    :error-message="$t('insert_your_street_and_number')"
                    :error-message-force="isWrongAdress2"
                    :value="user.shipping_address"
                    @input="user.shipping_address=$event.target.value"
                    clear-button
            ></f7-list-input>
            <f7-list-input
                    :label="$t('city')"
                    name="city2"
                    type="text"
                    :placeholder="$t('your_city')"
                    required
                    validate
                    data-validate-on-blur="true"
                    :error-message="$t('insert_your_city')"
                    :error-message-force="isWrongCity2"
                    :value="user.shipping_city"
                    @input="user.shipping_city=$event.target.value"
                    clear-button
            ></f7-list-input>
            <f7-list-input
                    :label="$t('psc')"
                    name="psc2"
                    type="number"
                    :placeholder="$t('your_psc')"
                    required
                    validate
                    data-validate-on-blur="true"
                    :error-message="$t('insert_your_psc')"
                    :error-message-force="isWrongPSC2"
                    :value="user.shipping_zip"
                    @input="user.shipping_zip=$event.target.value"
                    clear-button
            ></f7-list-input>
        </f7-list>
        <f7-list>
            <f7-list-button class="button-login" id="register-button" @click="register">
                {{$t("register")}}
            </f7-list-button>
        </f7-list>
    </div>
</template>
<script>

    export default {
        //el:'#register-button',
        props: ['update'],
        data() {
            return {
                user: JSON.parse(JSON.stringify(store.state.user)),
                shippingAddress: false,
                isWrongCompany: false,
                isWrongName: false,
                isWrongPhone: false,
                isWrongEmail: false,
                isWrongICO: false,
                isWrongDIC: false,
                isWrongPass1: false,
                isWrongPass2: false,
                isWrongAdress: false,
                isWrongCity: false,
                isWrongPSC: false,
                isWrongAdress2: false,
                isWrongCity2: false,
                isWrongPSC2: false


            };
        },
        methods: {
            register() {
                this.isWrongEmail = false;
                if (!this.user.email) {
                    this.isWrongEmail = true;
                    return;
                }
                if (!this.update) {
                    this.isWrongPass1 = false;
                    if (!this.user.pass1) {
                        this.isWrongPass1 = true;
                        return;
                    }
                    this.isWrongPass2 = false;
                    if (this.user.pass2 !== this.user.pass1) {
                        this.isWrongPass2 = true;
                        return;
                    }
                }

                //TODO posleme na server + nejaky feedback o ulozeni by sa zisiel
                beRequest('Customer', 'Register', {
                    user: this.user,
                    update: this.update
                }).then(result => {
                    if (result.user === false) {
                        alert(this.$t('email_exist'));
                        return;
                    }
                    this.$store.commit('setUser', result.user);
                    if (this.update) {
                        this.toast(this.$t('profile_saved'), 'checkmark_alt_circle');
                    } else {
                        this.navigate('/create-order');
                    }
                })
            },

            adress2check: function (e) {
                this.shippingAddress = true;
            },
        },
    }
</script>